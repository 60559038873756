#cookies-content-barre {
    position: fixed;
    z-index: 1010;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 5rem;
    background: rgba(243,243,243,.95);
    padding: 1.2rem
}

#cookies-options {
    padding: 30px;
    max-width: 1000px;
    width: 100%;
    hyphens: initial
}

#cookies-options .d-flex {
    display: flex
}

#cookies-options .h3 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 15px
}

#cookies-options .cookies-options-container .cookies-option-title {
    font-size: 18px
}

#cookies-options .cookies-options-container .cookies-detail-content {
    width: 100%;
    padding: 0 15px;
    line-height: 1
}

#cookies-options .cookies-detail-content .cookies-detail {
    font-size: 12px;
    cursor: pointer;
    transition: color .3s
}

#cookies-options .cookies-detail-content .cookies-detail-chevron {
    margin-left: 5px
}

#cookies-options .cookies-detail-content .table-responsive {
    margin-top: 12px;
    display: none
}

#cookies-options .cookies-options-container .cookies-option-title,
#cookies-options .cookies-options-container .swip-checkbox {
    margin-top: 20px
}

#cookies-options .cookies-options-container .cookies-option-description {
    max-width: 800px;
    line-height: 1.1
}

#cookies-options .cookies-options-container .cookies-option-title span {
    display: inline-block;
    margin-left: 10px;
    transition: transform .2s
}

#cookies-options .cookies-options-container .cookies-option-title span.active {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

#cookies-options .cookies-options-container:first-child .cookies-option-description {
    display: block
}

#cookies-options .cookies-detail-content table {
    width: 100%;
    font-size: 12px
}

#cookies-options .cookies-detail-content table thead {
    text-align: center;
    background-color: #eee
}

#cookies-options .cookies-detail-content table tbody tr:nth-child(2n) {
    background-color: #eee
}

#cookies-options .cookies-detail-content table tbody p {
    margin-bottom: 0
}

#cookies-options .alert-close {
    margin-top: 15px
}

#cookies-options .cookies-options-container:first-child .swip-checkbox::before {
    content: 'Obligatoire';
    position: absolute;
    top: 0;
    right: auto;
    margin-left: 3px;
    color: #b12626;
    font-size: 11px
}

#cookies-content,
#cookies-content p {
    font-size: 12px;
    padding: 12px;
    color: #000
}

#cookies-content {
    position: fixed;
    z-index: 60;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 50px;
    background: rgba(128,218,221,.9)
}

.cookies-waiting-container {
    display: flex;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    justify-content: center;
    align-items: center
}

.cookies-waiting-content {
    padding: 5px;
    text-align: center
}

.alert-txt {
    line-height: 1.3;
    min-height: 50px;
    margin: 0;
    vertical-align: middle
}

.alert-txt a {
    color: #e6007e
}

#cookies-content .alert-close {
    width: 350px;
    min-height: 30px;
    margin-top: 15px
}

.alert-close {
    display: flex;
    justify-content: flex-end
}

.alert-close a,
.cookies-waiting-content a {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: inline-block;
    min-height: 20px;
    padding: 10px 15px;
    text-decoration: none;
    color: #f2f2f1;
    background: #000
}

.alert-close a:hover {
    color: #fff;
    background: #000
}

.alert-close a.accepted {
    color: #fff;
    background: #000
}

.alert-close a.accepted:hover {
    color: #fff;
    border-color: #3cb192;
    background: #e6007e
}

.alert-close a + a,
.cookies-waiting-content a + a {
    margin-left: 10px
}

.alert-txt a:focus,
.alert-txt a:hover {
    opacity: .7
}

.fancybox-close-small::after {
    font-family: kfont;
    content: '\EA0C'
}

.swip-checkbox [type=checkbox]:checked,
.swip-checkbox [type=checkbox]:not(:checked) {
    position: absolute;
    left: -9999px
}

.swip-checkbox [type=checkbox]:checked + label,
.swip-checkbox [type=checkbox]:not(:checked) + label {
    position: relative;
    padding-left: 75px;
    cursor: pointer
}

.swip-checkbox [type=checkbox]:checked + label:after,
.swip-checkbox [type=checkbox]:checked + label:before,
.swip-checkbox [type=checkbox]:not(:checked) + label:after,
.swip-checkbox [type=checkbox]:not(:checked) + label:before {
    position: absolute;
    content: ''
}

.swip-checkbox [type=checkbox]:checked + label:before,
.swip-checkbox [type=checkbox]:not(:checked) + label:before {
    top: -1px;
    left: 0;
    width: 65px;
    height: 26px;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    transition: background-color .2s;
    border-radius: 15px;
    background: #ddd
}

.swip-checkbox [type=checkbox]:checked + label:after,
.swip-checkbox [type=checkbox]:not(:checked) + label:after {
    top: 2px;
    left: 5px;
    width: 20px;
    height: 20px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
    border-radius: 50%;
    background: #35c3c8
}

.swip-checkbox [type=checkbox]:checked + label:before {
    background: #35c3c8
}

.swip-checkbox [type=checkbox]:checked + label:after {
    top: 2px;
    left: 40px;
    background: #fff
}

.swip-checkbox [type=checkbox]:checked + label .ui,
.swip-checkbox [type=checkbox]:checked + label .ui:after,
.swip-checkbox [type=checkbox]:not(:checked) + label .ui:before {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    position: absolute;
    top: 0;
    left: 6px;
    width: 65px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
    border-radius: 15px
}

.swip-checkbox [type=checkbox]:not(:checked) + label .ui:before {
    font-family: kfont;
    top: 2px;
    left: 36px;
    content: '\EA0C';
    color: #35c3c8
}

.swip-checkbox [type=checkbox]:checked + label .ui:after {
    font-family: kfont;
    top: 2px;
    content: '\EA39';
    color: #fff
}

.swip-checkbox [type=checkbox]:focus + label:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid transparent;
    -ms-box-sizing: border-box
}

.picto-cross {
    position: absolute;
    top: 14px;
    left: 3px;
    display: block;
    width: 24px;
    height: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    background: #000
}

.picto-cross:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 2px;
    margin: 0;
    content: '';
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    background: #000
}

@media (max-width:767px) {
    #cookies-content-barre {
        padding: 5px
    }

    #cookies-content-barre .alert-close {
        flex-direction: column
    }

    #cookies-content-barre .alert-close a {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 5px
    }

    #cookies-options {
        padding: 30px 10px 10px
    }

    #cookies-options .cookies-options-container .cookies-option-title,
  #cookies-options .cookies-options-container .swip-checkbox {
        margin-top: 8px
    }

    .popin-cookies .fancybox-content {
        padding: 25px
    }

    .popin-cookies .fancybox-content .alert-close {
        margin-top: 20px
    }

    #cookies-options .cookies-options-container .swip-checkbox {
        margin-bottom: 2px;
        margin-left: auto
    }

    #cookies-options .cookies-options-container:first-child .swip-checkbox::before {
        right: 100px;
        top: auto;
        bottom: 14px
    }

    #cookies-options .cookies-detail-content {
        margin-bottom: 15px
    }

    .table-responsive,
  .table-responsive tbody,
  .table-responsive td,
  .table-responsive th,
  .table-responsive thead,
  .table-responsive tr {
        display: block
    }

    .table-responsive thead {
        display: none
    }

    .table-responsive td {
        position: relative;
        padding-left: 130px!important;
        padding-right: 10px;
        background: #fff
    }

    .table-responsive td::before {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        content: attr(data-type-col);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 120px;
        font-weight: 700;
        color: #fff;
        background-color: #00427c;
        text-transform: capitalize
    }

    .table-responsive td:nth-child(2n+1) {
        background-color: #eee
    }

    .table-responsive tr {
        margin-bottom: 10px
    }

    .table-responsive td,
  .table-responsive th {
        padding-top: 10px!important;
        padding-bottom: 10px!important;
        border-bottom: 1px solid #fff
    }
}